@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Outfit:wght@400;500&family=Plus+Jakarta+Sans:wght@400;500&family=Poppins&display=swap");
html {
  background-color: black;
}
.App {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loginText {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  font-size: 30px;
}
.labels {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  align-self: flex-start;
  margin-left: 1em;
  margin-top: 1.5em;
}
.login {
  margin-top: 5em;
  border-radius: 1em;
  background-color: #090909;
  display: flex;
  width: 20em;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 4em 3em;
}
@media screen and (max-width: 500px) {
  .labels {
    align-self: flex-start;
    margin-left: 5%;
  }
  .login {
    height: 100vh;
    margin-left: 1em;
    margin-top: 0em;
  }
}

.input {
  font-family: Outfit, sans-serif;
  border-radius: 1em;
  border-style: none;
  background-color: #dfeaf3;
  margin-top: 0.1em;
  max-width: 100%;

  color: #59595a;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  letter-spacing: -0.48px;
  justify-content: center;
  width: 20em;
  height: 2.3em;
  padding: 0.3em 0.7em;
}
.forgot_pw {
  align-self: flex-end;
  color: #a1a1aa;
  border-style: none;
  background-color: #ffffff00;
  margin-bottom: 1em;
  margin-top: 0.2em;
  text-decoration: none;
}
.loginButton {
  font-family: Outfit, sans-serif;
  border-radius: 40px;
  border-style: none;
  background-color: var(--Blue, #a0c6ff);
  font-size: 15px;
  font-weight: 600;
  padding: 1em 9.2em;
}
.register_button {
  align-self: center;
  color: #d6d6e5;
  background-color: #ffffff00;
  border-style: none;
  margin-bottom: 1em;
  font-weight: 700;
  margin-top: 1em;
  text-decoration: none;
}

.logo {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}
.logo_div {
  margin-top: -2em;
  max-width: 150px;
}
.error {
  color: #ff7d8f;
  font-family: Outfit, sans-serif;
  font-size: medium;
}
.signup_button {
  font-family: Outfit, sans-serif;
  border-radius: 40px;
  border-style: none;
  background-color: #dff4b4;
  font-size: 15px;
  font-weight: 600;
  width: 22em;
  height: 4em;
  margin-top: 2em;
}
.req {
  color: #ff7d8f;
  font-family: Outfit, sans-serif;
  font-size: medium;
}
.req_met {
  color: #7dff7d;
  font-family: Outfit, sans-serif;
  font-size: medium;
}
.character {
  font-family: Outfit, sans-serif;
  background: black;
  border-color: #3b3b3b;
  color: whitesmoke;
  border-width: 2px;
  border-radius: 0.2em;
}
.character--selected {
  background-color: #000000;
  border: none;
}
.character--filled {
  background-color: #2b2b2b;
}
